import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
/*import logo from "../../../logo.svg";*/
import book from "../../../assets/sbook.svg";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import Backdrop from "../UIElements/Backdrop";
import SideDrawer from "./SideDrawer";
import "./MainNavigation.scss";
import { AuthContext } from "../../context/auth-context";

const MainNavigation = ({ pseudo }) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const auth = useContext(AuthContext);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
       <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navig__drawer-nav">
          <NavLinks />
        </nav>
      </SideDrawer>
      <MainHeader>
        <button className="main-navig__menu-button" onClick={openDrawerHandler}>
          <span />
          <span />
          <span />
        </button>
        <div className="main-navig__title">
          <div className="main-navig__title-content">
            <Link to="/">
              <img
                src={book}
                className="main-navig-logo"
                alt="Livritude Home"
              />
              <span>Livritude </span>
             </Link>
          </div>
          {auth.isLoggedIn && <span className="main-navig__bonjour">Bonjour {pseudo}</span>}
        </div>
        <nav className="main-navig__header-nav">
          <NavLinks />
        </nav>
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;
