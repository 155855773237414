import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";


import MainNavigation from "./shared/components/Navigation/MainNavigation";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from './shared/hooks/auth-hooks';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

// import Users from "./user/pages/Users";
// import User from "./user/pages/User";
// import AuthenticateForm from "./user/pages/Auth";
// import UserBiblios from "./Biblio/pages/UserBiblios";
// import NewBiblio from "./Biblio/pages/NewBiblio";
// import UpdateBiblio from "./Biblio/pages/UpdateBiblio";

const User = React.lazy(() => import('./user/pages/User'));
const Users = React.lazy(() => import('./user/pages/Users'));
const UserEcrivains = React.lazy(() => import('./ecrivain/pages/UserEcrivains'));
const NewEcrivain = React.lazy(() => import('./ecrivain/pages/NewEcrivain'));
const UserLivres = React.lazy(() => import('./livre/pages/UserLivres'));
const NewLivre = React.lazy(() => import('./livre/pages/NewLivre'));
const UpdateLivre = React.lazy(() => import('./livre/pages/UpdateLivre'));
const UserBiblios = React.lazy(() => import('./Biblio/pages/UserBiblios'));
const NewBiblio = React.lazy(() => import('./Biblio/pages/NewBiblio'));
const UpdateBiblio = React.lazy(() => import('./Biblio/pages/UpdateBiblio'));
const AuthenticateForm = React.lazy(() => import('./user/pages/Auth'));


const App = () => {
 
const { token, login, logout, userId, pseudo } = useAuth();


  let routes;
  if (token) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Users></Users>
        </Route>
        <Route path="/user/:uid" exact>
          <User></User>
        </Route>
        <Route path="/:uid/auteurs" exact>
          <UserEcrivains></UserEcrivains>
        </Route>
        <Route path="/:uid/auteur/new" exact>
          <NewEcrivain></NewEcrivain>
        </Route>
        <Route path="/:uid/livres" exact>
          <UserLivres></UserLivres>
        </Route>
        <Route path="/:uid/livre/new" exact>
          <NewLivre></NewLivre>
        </Route>
        <Route path="/:uid/livre/:lid" exact>
          <UpdateLivre></UpdateLivre>
        </Route>
        <Route path="/:uid/biblios" exact>
          <UserBiblios></UserBiblios>
        </Route>
        <Route path="/biblio/new" exact>
          <NewBiblio />
        </Route>
        <Route path="/biblio/:bibid">
          <UpdateBiblio />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Users></Users>
        </Route>
        <Route path="/user/:uid" exact>
          <User></User>
        </Route>
        <Route path="/auth">
          <AuthenticateForm />
        </Route>
        <Redirect to="/auth" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token, //convert to true or false
        token: token,
        userId: userId,
        login: login,
        logout: logout
      }}
    >
      <Router>
        <MainNavigation pseudo ={pseudo} />
        <main><Suspense fallback={<div className="superCenter"><LoadingSpinner /></div>}>{routes}</Suspense></main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
