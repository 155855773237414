import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const [pseudo, setPseudo] = useState(null);
  const [stTokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((uid, token, pseudo, expirationDate) => {
    setToken(token);
    setUserId(uid);
    setPseudo(pseudo);

    /* gestion de la date d'expiration du token (1h)
     * soit on a deja une date passée en param retrouvée dans le local Storage car on etait deja loguée
     * soit on en crée une new */
    const localStoredTokenExpirationDate =
      expirationDate ||
      new Date(new Date().getTime() + 1000 * 60 * 60); /* time du moment + 1h */
    setTokenExpirationDate(localStoredTokenExpirationDate);

    /* permet de rester logué en storant dans le localstorage */
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
        pseudo: pseudo,
        expiration: localStoredTokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setTokenExpirationDate(null);
    setPseudo(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && stTokenExpirationDate) {
      const remainingMillSec =
        stTokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingMillSec);
    } else {
      clearTimeout(
        logoutTimer
      ); /* on kill le timer si on est deja logout ou plus de token */
    }
  }, [token, logout, stTokenExpirationDate]);

  /* permet de rester logué en lisant dans le localstorage */
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.pseudo,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return { token, login, logout, userId, pseudo };
};
