import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { FaUserTie } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { AuthContext } from "../../context/auth-context";

import "./NavLinks.scss";

const NavLinks = () => {
  const auth = useContext(AuthContext);

  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact><FiUsers size="1.2rem" /> Utilisateurs</NavLink>
      </li>
      {auth.isLoggedIn && (
        <li>
          <NavLink to={`/${auth.userId}/auteurs`}><FaUserTie size="1.2rem" /> Auteurs</NavLink>
        </li>
      )}
       {auth.isLoggedIn && (
        <li>
          <NavLink to={`/${auth.userId}/auteur/new`}>new Auteur</NavLink>
        </li>
      )}
       {auth.isLoggedIn && (
        <li>
          <NavLink to={`/${auth.userId}/livres`}>Livres</NavLink>
        </li>
      )}
       {auth.isLoggedIn && (
        <li>
          <NavLink to={`/${auth.userId}/livre/new`}>new LIVRE</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to={`/${auth.userId}/biblios`}>MY BIBLIOS</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/biblio/new">ADD BIBLIO</NavLink>
        </li>
      )}
      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/auth">AUTHENTICATE</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button onClick={auth.logout}>LOG OUT</button>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
